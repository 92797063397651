import * as React from "react";

interface IRenderErrorMsg {
    errors: any;
    touched: any;
    colorTheme: string;
    name: string;
}

export default (props: IRenderErrorMsg) => {
    if (props.colorTheme == "transparent") {
        return props.errors[props.name] && props.touched[props.name] ? <div className="genErrorMessageWrapper">
            <div className={"genErrorMessage genOpposite" + props.colorTheme}>
                <p>{props.errors[props.name]}</p>
            </div>
        </div> : <></>
    } else {
        return <div className="genErrorMessageWrapper">
            <div
                style={{
                    visibility:
                        props.errors[props.name] &&
                            props.touched[props.name]
                            ? "visible"
                            : "hidden"
                }}
                className={"genErrorMessage genOpposite" + props.colorTheme}
            >
                <p>{props.errors[props.name]}</p>
            </div>
        </div>
    }

}
