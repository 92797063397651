import "../polyfills";
import moment from "moment";

export const isNullOrWhiteSpace = (str: string) => {
  return !str || str.length === 0 || /^\s*$/.test(str);
};

export const kFormatter = (num: any) => {
  const number = parseInt((Math.abs(num) / 1000).toFixed(1));
  return Math.abs(num) > 999
    ? Math.sign(num) * number + "k"
    : Math.sign(num) * Math.abs(num);
};

export const numFormat = num => {
  if (num.toString().indexOf(".") !== -1) {
    var b = num.toLocaleString();
    return b;
  } else {
    var c = num.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    return c;
  }
};

export const formatCurrency = num => {
  num = num.toString().replace(/\$|\,/g, "");
  if (isNaN(num)) num = "0";
  const sign = num == (num = Math.abs(num));
  num = Math.floor(num * 100 + 0.50000000001);
  let cents = num % 100;
  let temp = cents.toString();
  num = Math.floor(num / 100).toString();
  if (cents < 10) temp = "0" + cents;
  const last = cents != 0 ? "." + temp : "";
  for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++)
    num =
      num.substring(0, num.length - (4 * i + 3)) +
      "," +
      num.substring(num.length - (4 * i + 3));
  return (sign ? "" : "-") + num + last;
};

export const isEmpty = (obj: Object) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
};

export const arrayEmptyOrNotExist = (array: any[] | undefined) => !array || array == undefined || (Array.isArray(array) && array.length == 0)

export const nameOf = <T>(name: Extract<keyof T, string>): string => name;

export const GetFormattedDateStr = (
  date: string | Date,
  originalformat: string,
  newFormat?: string
) =>
  moment(date, originalformat)
    .format(`${newFormat ? newFormat : "YYYY-MM-DD"}`)
    .toString();

export const unCamelCase = (str: string, lowerCase?: boolean) => {
  const temp = str
    // insert a space between lower & upper
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    // space before last upper in a sequence followed by lower
    .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
    // uppercase the first character
    .replace(/\s./g, match => match.toUpperCase())
    .replace(/^./, match => match.toUpperCase())
    .trim();
  if (lowerCase) {
    return temp.toLowerCase();
  } else {
    return temp;
  }
}

export const makeSingular = (plural: string) => plural.slice(0, -1)

export const generateGuidV4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}