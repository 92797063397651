import * as React from "react";

interface IFormButtonProps {
    colorTheme: string;
    buttonColorTheme?: string;
    errors: any;
    touched: any;
    buttonText: string;
    isEventForm: boolean;
    setHasSubmit: any;
    submitDisabled: boolean;
    buttonRoundedCorners: boolean;
}

export default (props: IFormButtonProps) => {
    const getClassName = (isEventForm: boolean) => {
        if (isEventForm) {
            return `genSubmitButton genBorder genSubmitHover${props.buttonColorTheme ? props.buttonColorTheme : props.colorTheme} ${props.buttonRoundedCorners ? 'genButtonRoundedCorners' : ''}`
        } else {
            return `genSubmitButton genBorderOpposite${props.colorTheme} genSubmitHover${props.buttonColorTheme ? props.buttonColorTheme : props.colorTheme} ${props.buttonRoundedCorners ? 'genButtonRoundedCorners' : ''}`
        }
    }
    return (
        <div className={`${props.isEventForm ? "eventButtonWrapper d-flex justify-content-center" : "genButtonWrapper"} genOpposite`}>
            <div className="align-self-center">
                <button
                    className={getClassName(props.isEventForm)}
                    disabled={props.submitDisabled}
                    type="submit"
                    data-toggle="modal"
                    onClick={() => props.setHasSubmit(true)}
                >
                    {props.buttonText}
                </button>
            </div>
        </div >
    );
}