import * as React from "react";
import { useSpring, animated } from "react-spring";

export interface ICounterProps {
    description?: string;
    countUpToNum?: any;
    isVisible?: any;
    colorTheme: string;
    counterStartNum: number;
}

export default (props: ICounterProps) => {
    const [springProps, setSpringProps] = useSpring(() => ({
        number: 0,
        from: { number: 0 }
    })) as any;

    if (props.isVisible) {
        setSpringProps({
            number: props.counterStartNum + props.countUpToNum,
            from: { number: 0 }
        });
    }

    return (
        <div className={"genCountCol genOpposite" + props.colorTheme}>
            <animated.div className="genCountNumber">
                {springProps.number.interpolate((x: any) => x.toFixed(0))}
            </animated.div>
            <p className="genCountDescription">{props.description}</p>
        </div>
    );
};
