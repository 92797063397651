import * as React from "react";
import "../Style/StandardForm.scss";
import FormField, { CustomFieldType, CustomFormFieldFactory } from "./FormField";
import { removeWhiteSpaces } from "./FunctionLib";
import { States, FormConstants, ICommonFieldsMeta } from "./Constants";
import { unCamelCase } from "../../../common/helper/helper";

interface IFormFieldsProps {
	errors: any;
	touched: any;
	colorTheme: string;
	setFieldValue: any;
	values: any;
	fields: IFieldProps[];
	isColumn: boolean;
	commonFieldsMeta: ICommonFieldsMeta;
	maxCommentLength?: number;
}

export interface IFieldProps {
	CustomFieldType: CustomFieldType;
	Name: string;
	Options?: string[];
	Placeholder: string;
	Required: boolean;
	Textarea: boolean;
	Label?: string;
	Type?: string;
	IsFullWidth: boolean;
}

const isOdd = (value: number) => {
	if (value % 2 != 0) return true;
	else return false;
};

const getFieldPlaceHodler = (fieldLabel: string, required: boolean) =>
	`${unCamelCase(fieldLabel)}${required ? "*" : ""}`;

export default (props: IFormFieldsProps) => {
	const numOfminimumField = 4;
	let fieldCount = props.commonFieldsMeta.State.Show ? numOfminimumField + 1 : numOfminimumField;
	const numOfExtraField = props.fields.length;
	fieldCount = fieldCount + numOfExtraField;
	const haveExtraField = numOfExtraField > 0 ? true : false;
	const fields = props.fields;
	const textAreafields = [] as IFieldProps[];
	fields.map((field: IFieldProps, i: number) => {
		if (field.Textarea) {
			textAreafields.push(fields[i]);
		}
	});
	return (
		<div className="row">
			{Object.keys(props.commonFieldsMeta).map((fieldName, i) =>
				["FirstName", "LastName"].includes(fieldName) &&
				props.commonFieldsMeta[fieldName]?.Show ? (
					<FormField
						key={i}
						setFieldValue={props.setFieldValue}
						fieldClassName="form-control standard-form-group"
						name={fieldName}
						placeholder={getFieldPlaceHodler(
							fieldName,
							props.commonFieldsMeta[fieldName]?.Required
						)}
						errors={props.errors}
						touched={props.touched}
						colorTheme={props.colorTheme}
						isColumn={props.isColumn}
					/>
				) : null
			)}
			{props.fields.map((field: IFieldProps, i: number) => {
				if (!field.Textarea && (field.Name === "Address" || field.Name === "Suburb")) {
					return CustomFormFieldFactory(
						field,
						props.setFieldValue,
						false,
						props.isColumn,
						i,
						props.errors,
						props.touched,
						props.colorTheme
					);
				}
			})}
			{props.commonFieldsMeta["Postcode"]?.Show ? (
				<FormField
					key="postcode"
					setFieldValue={props.setFieldValue}
					fieldClassName="form-control standard-form-group"
					name="Postcode"
					placeholder={getFieldPlaceHodler(
						"Postcode",
						props.commonFieldsMeta["Postcode"]?.Required
					)}
					errors={props.errors}
					touched={props.touched}
					colorTheme={props.colorTheme}
					isColumn={props.isColumn}
				/>
			) : null}
			{props.commonFieldsMeta.State.Show && (
				<FormField
					setFieldValue={props.setFieldValue}
					isLastField={!haveExtraField}
					colorTheme={props.colorTheme}
					className={!haveExtraField && "container"}
					fieldClassName="form-control standard-form-group"
					name={FormConstants.state}
					component="select"
					placeholder={getFieldPlaceHodler(
						FormConstants.state,
						props.commonFieldsMeta.State.Required
					)}
					errors={props.errors}
					touched={props.touched}
					isColumn={props.isColumn}
					children={States.map((opt: any, index: number) => (
						<option key={index} value={opt.value}>
							{opt.text}
						</option>
					))}
				/>
			)}
			{props.commonFieldsMeta["Email"]?.Show ? (
				<FormField
					key="email"
					setFieldValue={props.setFieldValue}
					fieldClassName="form-control standard-form-group"
					name="Email"
					placeholder={getFieldPlaceHodler(
						"Email",
						props.commonFieldsMeta["Email"]?.Required
					)}
					errors={props.errors}
					touched={props.touched}
					colorTheme={props.colorTheme}
					isColumn={props.isColumn}
				/>
			) : null}
			{props.fields.map((field: IFieldProps, i: number) => {
				if (!field.Textarea && field.Name !== "Address" && field.Name !== "Suburb") {
					const isLastField =
						i == numOfExtraField - 1 && textAreafields.length == 0 && isOdd(fieldCount)
							? true
							: false;
					return CustomFormFieldFactory(
						field,
						props.setFieldValue,
						isLastField,
						props.isColumn,
						i,
						props.errors,
						props.touched,
						props.colorTheme
					);
				}
			})}
			{textAreafields.length > 0
				? textAreafields.map((field: IFieldProps, i: number) => {
						const isLastField =
							i == textAreafields.length - 1 && isOdd(fieldCount) ? true : false;
						return (
							<FormField
								setFieldValue={props.setFieldValue}
								isColumn={props.isColumn}
								key={i}
								isLastField={isLastField}
								fieldClassName="form-control genTextarea"
								name={removeWhiteSpaces(field.Name)}
								placeholder={
									field.Required ? field.Placeholder + "*" : field.Placeholder
								}
								component={field.Textarea ? "textarea" : undefined}
								errors={props.errors}
								touched={props.touched}
								colorTheme={props.colorTheme}
								maxLength={props.maxCommentLength}
							/>
						);
				  })
				: null}
		</div>
	);
};
