import "../../common/polyfills";
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import StandardForm from './Components/StandardForm';

function MountForm(props: any, el: Element) {
    ReactDOM.render(<StandardForm {...props} />, el)
}

(window as any).MountForm = MountForm;


